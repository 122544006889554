import { font, margin, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { PageProps } from 'gatsby';
import React from 'react';

import { homeStyles } from '../style/Home.styles';
import KtmImage from 'assets/images/ktm.jpg';
import GumeImage from 'assets/images/gume.jpg';
import DijagnostikaImage from 'assets/images/dijagnostika.jpg';
import OpremaImage from 'assets/images/oprema.jpg';
import PrijevozImage from 'assets/images/prijevoz.jpg';
import { SEO } from 'components';

const HomePage: React.FC<PageProps> = ({ location }) => {
  return (
    <div css={homeStyles.root}>
      <SEO title={'DD Moto Servis'} href={location.href} />
      <div css={[homeStyles.title, margin.top.huge]}>
        <h1 css={[font.size.huge, text.color.text]}>DD moto</h1>
        <p css={[font.size.large, text.color.textLight, homeStyles.subtitle]}>
          servis skutera i motocikala
        </p>
        <div css={homeStyles.dotSpacer}>
          <div css={homeStyles.dot}></div>
          <div css={homeStyles.dot}></div>
          <div css={homeStyles.dot}></div>
        </div>
      </div>
      <div css={homeStyles.grid}>
        <div css={homeStyles.imageContainer}>
          <img css={homeStyles.image} src={KtmImage} />
        </div>
        <div css={homeStyles.jobDescriptionContainer}>
          <p css={homeStyles.jobTitle}>servis skutera i motocikala</p>
          <p css={homeStyles.jobDescription}>
            redovno održavanje i popravci skutera, maxi skutera i motocikala
          </p>
        </div>
        <div css={homeStyles.jobDescriptionContainer}>
          <p css={homeStyles.jobTitle}>izmjena guma i balansiranje</p>
          <p css={homeStyles.jobDescription}>
            vulkanizerske usluge - izmjena guma i balansiranje
          </p>
        </div>
        <div css={homeStyles.imageContainer}>
          <img css={homeStyles.image} src={GumeImage} />
        </div>
        <div css={homeStyles.imageContainer}>
          <img css={homeStyles.image} src={DijagnostikaImage} />
        </div>
        <div css={homeStyles.jobDescriptionContainer}>
          <p css={homeStyles.jobTitle}>kompjuterska dijagnostika</p>
          <p css={homeStyles.jobDescription}>
            za noviju generaciju motocikala i skutera imamo kompjutersku
            dijagnostiku
          </p>
        </div>
        <div css={homeStyles.jobDescriptionContainer}>
          <p css={homeStyles.jobTitle}>rezervni dijelovi</p>
          <p css={homeStyles.jobDescription}>
            u ponudi imamo veliki izbor originalnih i zamjenskih dijelova, guma,
            akumulatora i ulja
          </p>
        </div>
        <div css={homeStyles.imageContainer}>
          <img css={homeStyles.image} src={OpremaImage} />
        </div>
        <div css={homeStyles.imageContainer}>
          <img css={homeStyles.image} src={PrijevozImage} />
        </div>
        <div css={homeStyles.jobDescriptionContainer}>
          <p css={homeStyles.jobTitle}>usluge prijevoza</p>
          <p css={homeStyles.jobDescription}>prijevoz motocikala</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
