import { css } from '@emotion/react';
import {
  font,
  margin,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import { borderRadius, breakpoints, colors } from 'style/variables';
import { wrapper } from 'style/wrappers.styles';

const root = css`
  ${wrapper.medium}
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.medium} {
    ${wrapper.large}
  }
`;

const title = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 320px) {
    ${font.size.large}
  }
`;

const subtitle = css`
  background-color: ${colors.secondary};
  width: fit-content;
  ${padding.left.med}
  ${padding.right.med}
`;

const dot = css`
  width: 10px;
  height: 10px;
  background-color: ${colors.primary};
  border-radius: ${borderRadius.full};
`;

const dotSpacer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100px;
  ${margin.top.huge}
`;

const grid = css`
  width: 100%;
  ${margin.top.huge}
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 50px;

  @media ${breakpoints.small} {
    grid-column-gap: 5px;
  }

  @media ${breakpoints.medium} {
    grid-row-gap: 100px;
  }
`;

const imageContainer = css``;

const image = css`
  object-fit: cover;
  border-radius: ${borderRadius.full};
  height: 100px;
  width: 100px;
  filter: grayscale(65%) drop-shadow(4px 4px ${colors.secondary});

  @media ${breakpoints.small} {
    filter: grayscale(65%) drop-shadow(6px 6px ${colors.secondary});
    height: 200px;
    width: 200px;
  }

  @media ${breakpoints.medium} {
    filter: grayscale(65%) drop-shadow(8px 8px ${colors.secondary});
    height: 400px;
    width: 400px;
  }

  @media ${breakpoints.grid} {
    filter: grayscale(65%) drop-shadow(10px 10px ${colors.secondary});
    height: 500px;
    width: 500px;
  }
`;

const jobDescriptionContainer = css`
  align-items: center;
  width: 100px;

  @media ${breakpoints.small} {
    width: 200px;
  }

  @media ${breakpoints.medium} {
    width: 400px;
  }

  @media ${breakpoints.grid} {
    width: 500px;
  }
`;

const jobDescription = css`
  ${font.size.small}
  ${text.color.textLight}
  

  @media ${breakpoints.small} {
    ${font.size.base}
  }

  @media ${breakpoints.medium} {
    ${font.size.large}
  }
`;

const jobTitle = css`
  ${font.size.medium}
  ${margin.bottom.med}

  @media ${breakpoints.small} {
    ${font.size.large}
  }

  @media ${breakpoints.medium} {
    ${font.size.huge}
  }
`;

export const homeStyles = {
  root,
  title,
  subtitle,
  dot,
  dotSpacer,
  grid,
  image,
  imageContainer,
  jobDescription,
  jobDescriptionContainer,
  jobTitle,
};
